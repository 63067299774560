import { _InspectionData, _InspectionOrigin, _InspectionStatus, AddressType, EntityStatus } from '../../entities';
import { APICore } from './apiCore';
import axios from 'axios';

const api = new APICore();

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

function getAllInspections(authToken: string, queryParams?: any) {
    const endpoint = `${SERVER_BASE_URL}/inspection`;
    return api.get(`${endpoint}`, {
        headers: {
            Authorization: authToken,
        },
        params: {
            query: {
                ...queryParams,
            },
        },
    });
}

function updateInspection(
    data: {
        id: string;
        dueDate?: string;
        completedAt?: string;
        startedAt?: string;
        status?: _InspectionStatus;
        inspectionData?: _InspectionData[];
        assetId: string;
        inspectionTemplateId: string;
        inspectorId: string;
        entityStatus?: EntityStatus;
    },
    authToken: string
) {
    const endpoint = `${SERVER_BASE_URL}/inspection`;
    return api.update(`${endpoint}`, data, {
        headers: {
            Authorization: authToken,
        },
    });
}

function updateInspectionData(
    data: {
        id: string;
        stepId: string;
        questionId: string;
        response: any;
    },
    authToken: string
) {
    const endpoint = `${SERVER_BASE_URL}/inspection/data`;
    return api.update(`${endpoint}`, data, {
        headers: {
            Authorization: authToken,
        },
    });
}

function createInspection(
    data: {
        dueDate: string;
        assetId: string;
        inspectionTemplateId: string;
        inspectorId: string;
        origin: _InspectionOrigin;
    },
    authToken: string
) {
    const endpoint = `${SERVER_BASE_URL}/inspection/create`;
    return api.create(`${endpoint}`, data, {
        headers: {
            Authorization: authToken,
        },
    });
}

export { getAllInspections, updateInspection, createInspection, updateInspectionData };
