import { AddressType, EntityStatus } from '../../entities';
import { APICore } from './apiCore';
import axios from 'axios';

const api = new APICore();

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

function getAllRoles(authToken: string, queryParams?: any) {
    const endpoint = `${SERVER_BASE_URL}/role`;
    return api.get(`${endpoint}`, {
        headers: {
            Authorization: authToken,
        },
        params: {
            query: {
                ...queryParams,
            },
        },
    });
}

function updateRole(
    data: {
        id: string;
        roleType?: string;

        permissions?: string[];
        entityStatus?: EntityStatus;
    },
    authToken: string
) {
    const endpoint = `${SERVER_BASE_URL}/role`;
    return api.update(`${endpoint}`, data, {
        headers: {
            Authorization: authToken,
        },
    });
}

function createRole(
    data: {
        roleType: string;

        permissions: string[];
    },
    authToken: string
) {
    const endpoint = `${SERVER_BASE_URL}/role`;
    return api.create(`${endpoint}`, data, {
        headers: {
            Authorization: authToken,
        },
    });
}

export { getAllRoles, updateRole, createRole };
