import { EntityStatus } from '../../entities';
import { APICore } from './apiCore';
import axios from 'axios';

const api = new APICore();

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

function getAllInspectorQualifications(authToken: string, queryParams: any) {
    const endpoint = `${SERVER_BASE_URL}/qualification`;
    return api.get(`${endpoint}`, {
        headers: {
            Authorization: authToken,
        },
        params: {
            query: {
                ...queryParams,
            },
        },
    });
}

function updateInspectorQualification(
    data: {
        id: string;
        title?: string;
        description?: string;
        permittedExtensions?: string[];
        entityStatus?: EntityStatus;
        shortTitle?: string;
    },
    authToken: string
) {
    const endpoint = `${SERVER_BASE_URL}/qualification`;
    return api.update(`${endpoint}`, data, {
        headers: {
            Authorization: authToken,
        },
    });
}

function createInspectorQualification(
    data: {
        title: string;
        description?: string;
        permittedExtensions?: string[];
        shortTitle: string;
    },
    authToken: string
) {
    const endpoint = `${SERVER_BASE_URL}/qualification`;
    return api.create(`${endpoint}`, data, {
        headers: {
            Authorization: authToken,
        },
    });
}

function deleteInspectorQualification(id: string, authToken: string) {
    const endpoint = `${SERVER_BASE_URL}/qualification`;
    return api.delete(`${endpoint}`, {
        headers: {
            Authorization: authToken,
        },
        params: {
            path: [id],
        },
    });
}

export {
    getAllInspectorQualifications,
    updateInspectorQualification,
    createInspectorQualification,
    deleteInspectorQualification,
};
