import { AddressType, EntityStatus } from '../../entities';
import { APICore } from './apiCore';
import axios from 'axios';

const api = new APICore();

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

function getAllAssets(authToken: string, queryParams?: any) {
    const endpoint = `${SERVER_BASE_URL}/asset`;
    return api.get(`${endpoint}`, {
        headers: {
            Authorization: authToken,
        },
        params: {
            query: {
                ...queryParams,
            },
        },
    });
}

function getAssetById(authToken: string, assetId: string) {
    const endpoint = `${SERVER_BASE_URL}/asset`;
    return api.get(`${endpoint}`, {
        headers: {
            Authorization: authToken,
        },
        params: {
            path: {
                assetId: assetId,
            },
        },
    });
}

function updateAsset(
    data: {
        id: string;
        name?: string;
        description?: string;
        address?: AddressType;
        entityStatus?: EntityStatus;
        assetNumber?: string;
        assetTypeId?: string;
    },
    authToken: string
) {
    const endpoint = `${SERVER_BASE_URL}/asset`;
    return api.update(`${endpoint}`, data, {
        headers: {
            Authorization: authToken,
        },
    });
}

function createAsset(
    data: {
        name: string;
        assetTypeId: string;
        description?: string;
        address?: AddressType;
        assetNumber?: string;
    },
    authToken: string
) {
    const endpoint = `${SERVER_BASE_URL}/asset`;
    return api.create(`${endpoint}`, data, {
        headers: {
            Authorization: authToken,
        },
    });
}

function deleteAsset(id: string, authToken: string) {
    const endpoint = `${SERVER_BASE_URL}/asset`;
    return api.delete(`${endpoint}`, {
        headers: {
            Authorization: authToken,
        },
        params: {
            path: [id],
        },
    });
}

export { getAllAssets, updateAsset, createAsset, deleteAsset, getAssetById };
