import { AddressType, EntityStatus } from '../../entities';
import { APICore } from './apiCore';
import axios from 'axios';

const api = new APICore();

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

function getAllInspectors(authToken: string, queryParams?: any) {
    const endpoint = `${SERVER_BASE_URL}/inspector`;
    return api.get(`${endpoint}`, {
        headers: {
            Authorization: authToken,
        },
        params: {
            query: {
                ...queryParams,
            },
        },
    });
}

function updateQualification(
    data: {
        id: string;
        qualification: {
            assignedQualificationId: string;
            qualificationTypeId: string;
            qualificationData: {
                qualifiedOn?: string;
                expiresOn?: string;
                attachments: string[];
                description?: string;
            };
        };
    },
    authToken: string
) {
    const endpoint = `${SERVER_BASE_URL}/inspector/qualification`;
    return api.update(`${endpoint}`, data, {
        headers: {
            Authorization: authToken,
        },
    });
}

function softdeleteQualification(pathParams: string[], authToken: string) {
    const endpoint = `${SERVER_BASE_URL}/inspector/qualification`;
    return api.delete(`${endpoint}`, {
        headers: {
            Authorization: authToken,
        },
        params: {
            path: pathParams,
        },
    });
}

function assignQualification(
    data: {
        id: string;
        qualification: {
            qualificationTypeId: string;
            qualificationData: {
                qualifiedOn: string;
                expiresOn?: string;
                attachments: string[];
                description?: string;
            };
        };
    },
    authToken: string
) {
    const endpoint = `${SERVER_BASE_URL}/inspector/qualification`;
    return api.create(`${endpoint}`, data, {
        headers: {
            Authorization: authToken,
        },
    });
}

function getInspectorById(authToken: string, pathParam: any) {
    const endpoint = `${SERVER_BASE_URL}/inspector`;
    return api.get(`${endpoint}`, {
        headers: {
            Authorization: authToken,
        },
        params: {
            path: {
                ...pathParam,
            },
        },
    });
}

export { getAllInspectors, getInspectorById, updateQualification, assignQualification, softdeleteQualification };
