import { _InspectionStep, AddressType, EntityStatus } from '../../entities';
import { APICore } from './apiCore';
import axios from 'axios';

const api = new APICore();

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

function getAllInspectionTemplates(authToken: string, queryParams?: any) {
    const endpoint = `${SERVER_BASE_URL}/template`;
    return api.get(`${endpoint}`, {
        headers: {
            Authorization: authToken,
        },
        params: {
            query: {
                ...queryParams,
            },
        },
    });
}

function updateInspectionTemplate(
    data: {
        id: string;
        name?: string;
        description?: string;
        entityStatus?: EntityStatus;
        assetTypeId?: string;
        steps?: _InspectionStep[];
    },
    authToken: string
) {
    const endpoint = `${SERVER_BASE_URL}/template`;
    return api.update(`${endpoint}`, data, {
        headers: {
            Authorization: authToken,
        },
    });
}

function createInspectionTemplate(
    data: {
        name: string;
        assetTypeId: string;
        description?: string;
        steps?: _InspectionStep[];
    },
    authToken: string
) {
    const endpoint = `${SERVER_BASE_URL}/template`;
    return api.create(`${endpoint}`, data, {
        headers: {
            Authorization: authToken,
        },
    });
}

function deleteInspectionTemplate(id: string, authToken: string) {
    const endpoint = `${SERVER_BASE_URL}/template`;
    return api.delete(`${endpoint}`, {
        headers: {
            Authorization: authToken,
        },
        params: {
            path: [id],
        },
    });
}

export { getAllInspectionTemplates, updateInspectionTemplate, deleteInspectionTemplate, createInspectionTemplate };
