import { FileType } from '../../components/FileUploader';
import { EntityStatus } from '../../entities';
import { APICore } from './apiCore';
import axios from 'axios';

const api = new APICore();

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

function uploadFile(files: FileType[], authToken: string) {
    const endpoint = `${SERVER_BASE_URL}/upload`;

    return api.createWithFile(`${endpoint}`, files, {
        headers: {
            Authorization: authToken,
        },
    });
}

function getAttachmentDataByIds(
    data: {
        files: string[];
    },
    authToken: string
) {
    const endpoint = `${SERVER_BASE_URL}/attachment`;
    return api.create(`${endpoint}`, data, {
        headers: {
            Authorization: authToken,
        },
    });
}

function removeFile(id: string, authToken: string) {
    const endpoint = `${SERVER_BASE_URL}/upload`;
    return api.delete(`${endpoint}`, {
        headers: {
            Authorization: authToken,
        },
        params: {
            path: [id],
        },
    });
}

export { uploadFile, getAttachmentDataByIds, removeFile };
