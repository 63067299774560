import { AddressType, EntityStatus } from '../../entities';
import { APICore } from './apiCore';
import axios from 'axios';

const api = new APICore();

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

function getAllOrganizations(authToken: string, queryParams?: any) {
    const endpoint = `${SERVER_BASE_URL}/organization`;
    return api.get(`${endpoint}`, {
        headers: {
            Authorization: authToken,
        },
        params: {
            query: {
                ...queryParams,
            },
        },
    });
}

function getOrganizationById(authToken: string, orgId: string) {
    const endpoint = `${SERVER_BASE_URL}/organization`;
    return api.get(`${endpoint}`, {
        headers: {
            Authorization: authToken,
        },
        params: {
            path: {
                id: orgId,
            },
        },
    });
}

function updateOrganization(
    data: {
        id: string;
        fullName?: string;
        shortName?: string;
        entityStatus?: EntityStatus;
    },
    authToken: string
) {
    const endpoint = `${SERVER_BASE_URL}/organization`;
    return api.update(`${endpoint}`, data, {
        headers: {
            Authorization: authToken,
        },
    });
}

function createOrganization(
    data: {
        fullName: string;
        shortName: string;
    },
    authToken: string
) {
    const endpoint = `${SERVER_BASE_URL}/organization`;
    return api.create(`${endpoint}`, data, {
        headers: {
            Authorization: authToken,
        },
    });
}

function createAdminUser(
    data: {
        firstName: string;
        lastName: string;
        dateOfBirth: string;
        phoneNumber: string;
        emailId: string;
        roles: {
            roleId: string;
            roleType: string;
        }[];
        password: string;
        orgId: string;
    },
    authToken: string
) {
    const endpoint = `${SERVER_BASE_URL}/organization/user`;
    return api.create(`${endpoint}`, data, {
        headers: {
            Authorization: authToken,
        },
    });
}

export { getAllOrganizations, getOrganizationById, updateOrganization, createOrganization, createAdminUser };
